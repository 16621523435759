const firebaseConfig = {
  apiKey: "AIzaSyCoeQ2Xqr6uLphqULveHD8_2oA7avNoHGI",
  authDomain: "react-e-comerce-abb80.firebaseapp.com",
  databaseURL: "https://react-e-comerce-abb80.firebaseio.com",
  projectId: "react-e-comerce-abb80",
  storageBucket: "react-e-comerce-abb80.appspot.com",
  messagingSenderId: "655201767127",
  appId: "1:655201767127:web:69e0443f2b0cf907efaa92",
  measurementId: "G-828WBFWG9Y"
};

export default firebaseConfig;
